import BoldSpinner from 'components/BoldSpinner';
import ErrorDetails from 'components/ErrorDetails';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
  } from 'react';
import useAssets from 'hooks/useAssets';
import useConnectedAccount from 'hooks/useConnectedAccount';
import useGoldlistMint from 'hooks/useGoldlistMint';
import useTranslate from 'hooks/useTranslate';
import { FaMinus, FaPlus } from 'react-icons/fa';
import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';

const GoldlistContainer = () => {
  const translate = useTranslate();
  const [address] = useConnectedAccount();

  const [amountToClaim, setAmountToClaim] = useState<number | undefined>();

  const { goldlistClaimedCount, readGoldlistClaimedCount, goldlistEntry } =
    useAssets();

  const maxClaim = useMemo(() => {
    if (goldlistEntry) {
      return goldlistEntry[1];
    }
    return 0;
  }, [goldlistEntry]);

  const availableToClaim = useMemo(() => {
    if (maxClaim && goldlistClaimedCount !== undefined) {
      return maxClaim - goldlistClaimedCount;
    }
    return 0;
  }, [maxClaim, goldlistClaimedCount]);

  const handleMintGoldlistSuccess = useCallback(() => {
    readGoldlistClaimedCount();
  }, [readGoldlistClaimedCount]);

  const [mintGoldlist, isGoldlistMinting] = useGoldlistMint(
    address,
    amountToClaim,
    maxClaim,
    handleMintGoldlistSuccess
  );

  const handleClaim = useCallback(() => {
    mintGoldlist();
  }, [mintGoldlist]);

  const handleClaimAmountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(event.target.value);

      if (value) {
        if (value <= availableToClaim) {
          setAmountToClaim(parseInt(event.target.value, 10));
        }
      } else {
        setAmountToClaim(undefined);
      }
    },
    [availableToClaim]
  );

  useEffect(() => {
    if (availableToClaim) {
      setAmountToClaim(availableToClaim);
    }
  }, [availableToClaim]);

  return (
    <Container maxW="sm">
      <Box bg="dark.800" py="12" px="8" w="full" borderRadius="lg">
        {availableToClaim > 0 ? (
          <VStack spacing="8" align="center">
            <VStack w="full" spacing="1">
              <Heading fontSize="4xl" fontWeight="800">
                {translate('goldlist:title')}
              </Heading>

              <Text opacity="0.8">{translate('goldlist:description')}</Text>
            </VStack>

            <Box w="full">
              <HStack w="full" spacing="3">
                <IconButton
                  size="sm"
                  borderRadius="full"
                  variant="outline"
                  icon={<Icon as={FaMinus} />}
                  aria-label="Minus"
                  isDisabled={!amountToClaim}
                  onClick={() => {
                    setAmountToClaim((amountToClaim || 1) - 1);
                  }}
                />

                <Input
                  value={amountToClaim}
                  type="number"
                  min={1}
                  max={availableToClaim}
                  onChange={handleClaimAmountChange}
                  textAlign="center"
                  size="lg"
                />

                <IconButton
                  size="sm"
                  borderRadius="full"
                  variant="outline"
                  icon={<Icon as={FaPlus} />}
                  aria-label="Plus"
                  isDisabled={amountToClaim === availableToClaim}
                  onClick={() => {
                    setAmountToClaim((amountToClaim || 0) + 1);
                  }}
                />
              </HStack>

              <Text textAlign="center" fontSize="xs" opacity={0.6} mt="1">
                {translate('goldlist:maxClaim', { maxClaim: availableToClaim })}
              </Text>
            </Box>

            <Button
              size="lg"
              px="12"
              w="full"
              isLoading={isGoldlistMinting}
              isDisabled={!amountToClaim}
              onClick={handleClaim}
            >
              {amountToClaim
                ? amountToClaim > 1
                  ? translate('common:claim:multi', { count: amountToClaim })
                  : translate('common:claim:one')
                : translate('common:claim:none')}
            </Button>
          </VStack>
        ) : (
          <>
            {goldlistClaimedCount === undefined && (
              <Center>
                <BoldSpinner size="xl" />
              </Center>
            )}
            {goldlistEntry === undefined && (
              <ErrorDetails
                title={translate('goldlist:entryNotFound')}
                description={
                  <Badge
                    bg="dark.500"
                    color="white"
                    textTransform="none"
                    fontWeight="normal"
                  >
                    {address}
                  </Badge>
                }
              />
            )}
            {goldlistEntry !== undefined && goldlistClaimedCount !== undefined && (
              <ErrorDetails
                title={translate('goldlist:allMinted')}
                description={
                  <Badge
                    bg="dark.500"
                    color="white"
                    textTransform="none"
                    fontWeight="normal"
                  >
                    {address}
                  </Badge>
                }
              />
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default GoldlistContainer;
